import { CtaButton } from "components/CtaButton";
import { HeadFC } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const NotFoundPage = () => {
	return (
		<main className="flex flex-col justify-center h-full uppercase">
			<div className="grid md:h-[479px]">
				<StaticImage
					src="../images/404-bg-m.png"
					alt=""
					layout="fullWidth"
					className="col-span-full row-span-full md:hidden"
				/>
				<StaticImage
					src="../images/ttl01_bg.png"
					alt=""
					layout="fullWidth"
					className="hidden col-span-full row-span-full md:block"
				/>
				<div className="col-span-full row-span-full font-bold drop-shadow-[0_0_45px_#8608C1] flex flex-col items-center justify-center">
					<h1 className="text-lg lg:text-4xl">Page not found</h1>
					<p className="text-[100px] lg:text-[200px] leading-none -order-1">
						404
					</p>
				</div>
			</div>
			<div className="text-center">
				<CtaButton to="/">Home</CtaButton>
			</div>
		</main>
	);
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
